@keyframes stretch {
    0% {
      transform: scale(.3);
      background-color:white;      
    }   
    100% {
      transform: scale(1);
      background-color: #F3FAFF;
    }
}

.topRow{
    display: flex;    
    justify-content: space-between;
    align-items: center;
    padding-left: 15px;
    background: #004588;
    color: white;
}

.closeIcon{
    padding: 10px 15px;
    display: inline-block;
}
.closeIcon:hover{
    cursor: pointer;
}

.couponPopUpBlock{
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    background: #F3FAFF;
    animation-name: stretch;
    animation-duration: 0.3s; 
    animation-timing-function: ease-out;       
}

.couponRow{
    padding: 10px 15px;
    margin: 10px;
    background-color: white;
    /* border: 1px solid #e7e7e7; */
}

.coupon_copy_row{
    display: flex;
    justify-content: space-between;
    align-items: center;    
}

.coupon_copy{
    color: #1E5698;
    font-size: 12px;
    font-weight: 600;
}
.coupon_copy:hover{
    cursor: pointer;
}

.coupon{
   background-color: #F3FAFF;
   border: 1px solid #e7e7e7;   
   padding: 5px 15px;
   font-weight: 600;
}
