.singalbooking-view-box {
    display: flex;         
  }

.singalbooking-view-box-outer {    
    border: 1px solid #eee;
    margin: 15px 0 0;
    background: #fbfbfb;
}

.singalbooking-view-box .booking-view-img-wrapper{
    width: 320px;
    max-width: 100%;
}


@media (max-width: 992px) {
    .singalbooking-view-box{
        display: block !important;
    }
    .singalbooking-view-box .booking-view-img-wrapper{
        width: 100%;
        max-width: 100%;
    }
}

